<!--
 * @Autor: oops.liu
 区域选择
-->
<template>
  <div class="domain_list">
    <div
      class="domain"
      v-for="item in list"
      :key="item.id"
      @click="handle(item)"
      :style="{ width: width }"
    >
      <div class="icon_box">
        <img class="icon" :src="item.picUrls[0] || item.cover" alt="" />
        <div
          class="badge_box"
          v-if="item.isbadge && item.badge > 0"
          :style="{ top: offset_y, right: offset_x }"
        >
          <span class="badge">{{ item.badge | large }}</span>
        </div>
      </div>
      <div class="label text_hide">{{ item.name || item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: "25%",
    },
    offset_x: {
      type: String,
      default: "26rpx",
    },
    offset_y: {
      type: String,
      default: "-12rpx",
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handle(item) {
      this.$emit("jump", item);
    },
  },
  filters: {
    large(badge) {
      return badge > 99 ? "99+" : badge;
    },
  },
  created() {},
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.domain_list {
  box-sizing: border-box;
  padding: 20rpx 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .domain {
    text-align: center;
    margin-bottom: 9px;
    padding-top: 5px;
    position: relative;

    .icon_box {
      position: relative;
      .icon {
        width: 22px;
        height: 22px;
      }

      .badge_box {
        position: absolute;

        height: 13px;
        width: 13px;
        background: $main;
        overflow: hidden;
        font-size: 12px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        z-index: 1;

        .badge {
          display: block;
          font-size: 12px;
          transform: scale(0.6);
        }
      }
    }
    .label {
      padding-top: 3px;
      font-size: 12px;
      color: #666;
    }
  }
}
</style>
