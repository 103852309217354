<template>
  <div class="category" @touchmove.prevent="">
    <top-head
      :sellerinfo="sellerInfo"
      @left="leftHandle"
      @right="rightHandle"
      @search="search"
      @details="details"
      @follow="follow"
      @cancle="cancle"
    />
    <div class="category_content">
      <div class="content">
        <div class="sidebar_box">
          <scroller>
            <div class="sidebar_content">
              <van-sidebar v-model="activeKey" @change="sideChange">
                <van-sidebar-item
                  v-for="item in categoryList"
                  :key="item.id"
                  :title="item.name"
                />
              </van-sidebar>
            </div>
          </scroller>
        </div>
        <div class="domain_box">
          <div class="domain_title" @click="nextHandle">
            {{ categoryList[activeKey] ? categoryList[activeKey].name : "" }}
            <!-- <i class="next"></i> -->
          </div>
          <div class="domain_conent">
            <scroller
              ref="my_scroll"
              :noDataText="noDataText"
              :on-infinite="infinite"
              :on-refresh="refresh"
            >
              <div class="domain_content_box">
                <domain :list="listData" width="33.33%" @jump="handleGoods" />
              </div>
            </scroller>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import headEvent from "@/mixin/headEvent.js";
import scrollMethods from "@/mixin/scroll.js";
import handleGoods from "@/mixin/handleGoods.js";
import topHead from "components/shop/topHead.vue";
import domain from "components/common/domain.vue";
export default {
  components: { topHead, domain },
  mixins: [headEvent, scrollMethods, handleGoods],
  data() {
    return {
      activeKey: 0,
      categoryId: 0,
      noDataText: "",
    };
  },
  computed: {
    ...mapState({
      goods: (state) => state.app.goods,
      sellerInfo: (state) => state.seller.sellerInfo,
      categoryList: (state) => state.seller.categoryList,
    }),
    shopId() {
      return this.$route.query.shopId;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      listLoad: "seller/loadGoodsCategory",
      load: "seller/loadGoodsList",
    }),
    async loadInfo(isRefresh = false) {
      const data = await this.load({
        current: this.pages,
        categoryShopFirst: this.categoryId,
      });
      const records = data.records || [];
      this.dataLength = records.length;
      if (isRefresh) {
        this.listData = records;
      } else {
        this.listData = this.listData.concat(records);
      }
      return data;
    },
    sideChange(index) {
      this.activeKey = index;
      this.categoryId = this.categoryList[index].id;
      this.listData = [];
      this.pages = 1;
      this.loadInfo();
    },
    domainHandle(item) {
      console.log(item);
    },
    nextHandle() {
      console.log("next");
    },
  },
  created() {
    this.listLoad(this.shopId).then(() => {
      this.categoryId = this.categoryList[this.activeKey].id;
      this.loadInfo();
    });
  },
};
</script>
<style scoped lang="scss">
@import "./index.scss";
</style>
